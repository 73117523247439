import {ArtData, ArtStatus, CategoryData} from "./content-data-types";

export const categories: CategoryData[] = [
	{
		id: "watercolor",
		name: "Watercolors",
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHollow.jpg?alt=media&token=4ab083b0-baf4-478b-9e8c-32e7e01ee9e6"
	},
	{
		id: "mm3d",
		name: "3D Mixed Media",
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHome.jpg?alt=media&token=c59ecf64-fecb-4569-a07b-c3c12aa9efb6"
	},
	{
		id: "collage",
		name: "Collage",
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FOnMeditation.jpg?alt=media&token=0edb2047-0de6-428b-bbb0-951fc47fd54d"
	},
	{
		id: "figure",
		name: "The Figure",
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FTheWomen.jpg?alt=media&token=f861f2b7-5556-45f6-bab7-d198d383978c"
	},
	{
		id: "oil",
		name: "Oil Paintings",
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FBethlehemPA.jpg?alt=media&token=cc50b4cc-eba7-4bd9-9ed9-4bd1dabf4eab"
	},
	{
		id: "hope-springs",
		name: "Hope Springs Project",
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings_WhiteLies.jpg?alt=media&token=e908b29d-af21-41f5-b1c1-bb6daf5010a5",
		desc: "The Hope Springs Project began as a contemplation of where I next wanted to live. It revolved into an abstraction of various locations and the ideals of honesty and environmentalism. Although I feel the Project nearing its end, it is still ongoing and I look forward to adding new pieces in the coming year."
	},
	{
		id: "mandala",
		name: "Mandala Imagery",
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FYinYangMandala.jpg?alt=media&token=31bf94d5-5f3e-4826-94ec-5ad970089154"
		// TODO: add description
	},
	{
		id: "tapestry",
		name: "Tapestry",
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FAfternoonOfAFaun.jpg?alt=media&token=7a83db62-dc1b-4c94-94a6-f15c095ff897"
	},
	{
		id: "icons",
		name: "Icons",
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FSeptic.jpg?alt=media&token=ab9b5266-9635-46b2-af71-11c7164f3046",
		wide: true
	}
];

export const art: ArtData[] = [
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FOnMeditation.jpg?alt=media&token=0edb2047-0de6-428b-bbb0-951fc47fd54d",
		title: "On Meditation",
		category: "collage",
		width: 5,
		height: 8,
		year: 2020,
		medium: "Paper collage"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FAlignment.jpg?alt=media&token=db40bdd8-072d-47ba-840e-6b33428e5ea9",
		title: "Alignment",
		category: "oil",
		width: 12,
		height: 20,
		year: 2009,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHome.jpg?alt=media&token=c59ecf64-fecb-4569-a07b-c3c12aa9efb6",
		title: "Home",
		category: "mm3d",
		width: 15,
		height: 15,
		year: 2016,
		medium: "Mixed media with animal jawbone",
		status: ArtStatus.SOLD
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FWeekendInNewEngland.jpg?alt=media&token=c70bdc53-7b9a-425d-80f1-5c1b739b5b1c",
		title: "Weekend in New England",
		category: "oil",
		width: 18,
		height: 23,
		year: 2010,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHomeBirds1.jpg?alt=media&token=f9aadd83-2704-4aab-90d2-ad225662a486",
		title: "Home Birds 1",
		category: "mm3d",
		width: 8,
		height: 10,
		year: 2015,
		medium: "Mixed media"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FTheWomen.jpg?alt=media&token=f861f2b7-5556-45f6-bab7-d198d383978c",
		title: "The Women",
		category: "figure",
		width: 24,
		height: 36,
		year: 2021,
		medium: "Oil on panel"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FTheMen.jpg?alt=media&token=583c0138-568f-47c3-8207-ca2c9a07a64e",
		title: "The Men",
		category: "figure",
		width: 24,
		height: 36,
		year: 2021,
		medium: "Oil on panel"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FStudyInRose.jpg?alt=media&token=e989fb37-9fde-498e-8d72-527bc6c7ec04",
		title: "Study in Rose",
		category: "figure",
		width: 18,
		height: 24,
		year: 2020,
		medium: "Oil on cardboard"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FBarred.jpg?alt=media&token=db39f85e-42d2-489c-994b-9971af86e0cb",
		title: "Barred",
		category: "oil",
		width: 18,
		height: 23,
		year: 2020,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FSeptic.jpg?alt=media&token=ab9b5266-9635-46b2-af71-11c7164f3046",
		title: "Septic",
		category: "icons",
		approx: true,
		width: 60,
		height: 12,
		year: 2020,
		medium: "Mixed media on paper",
		wide: true
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FLoss.jpg?alt=media&token=6cadfc37-d659-4d6e-86a7-baab89823875",
		title: "Loss",
		category: "watercolor",
		width: 8.5,
		height: 12,
		year: 2016,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FLost.jpg?alt=media&token=bde1ab73-b50c-48e9-b554-4836aea02a75",
		title: "Lost",
		category: "watercolor",
		width: 8.5,
		height: 12,
		year: 2016,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FChicago.jpg?alt=media&token=179534ea-d2ea-4717-828d-7bb61b01fb00",
		title: "Chicago",
		category: "watercolor",
		width: 8.5,
		height: 12,
		year: 2016,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FBoston.jpg?alt=media&token=1b5663d8-70aa-407d-956a-ed5feeb8d4d0",
		title: "Boston",
		category: "watercolor",
		width: 8.5,
		height: 12,
		year: 2016,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FLife.jpg?alt=media&token=83d29c76-2324-4f80-8ffa-7caf6b476bc4",
		title: "Life",
		category: "watercolor",
		width: 8.5,
		height: 12,
		year: 2017,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHomes.jpg?alt=media&token=3d289599-76dd-4904-82c5-b9da71012318",
		title: "Homes",
		category: "watercolor",
		width: 8.5,
		height: 12,
		year: 2017,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHollow.jpg?alt=media&token=4ab083b0-baf4-478b-9e8c-32e7e01ee9e6",
		title: "Hollow",
		category: "watercolor",
		width: 8.5,
		height: 12,
		year: 2017,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FFilled.jpg?alt=media&token=e6b300cb-5da2-4996-b037-91fe0708d243",
		title: "Filled",
		category: "watercolor",
		width: 8.5,
		height: 12,
		year: 2017,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FGrief.jpg?alt=media&token=2b073069-8258-410e-b9e1-c4e9be1015f6",
		title: "Grief",
		category: "watercolor",
		width: 8.5,
		height: 12,
		year: 2016,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FMerging.jpg?alt=media&token=ba4e49f8-efce-4d3c-bcc9-40d2c4bb00f5",
		title: "Merging",
		category: "watercolor",
		width: 8.5,
		height: 12,
		year: 2016,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FMoon1.jpg?alt=media&token=f71720be-e257-4e6c-9e53-c1c510bbd0fb",
		title: "Moon 1",
		category: "watercolor",
		width: 8.5,
		height: 12,
		year: 2016,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FMoon2.jpg?alt=media&token=20c510d6-3899-4e31-89a7-80fa41011cf6",
		title: "Moon 2",
		category: "watercolor",
		width: 8.5,
		height: 12,
		year: 2016,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FMotion.jpg?alt=media&token=d37d2e65-4cf1-420c-b541-9110cc51112b",
		title: "Motion",
		category: "watercolor",
		width: 8.5,
		height: 12,
		year: 2018,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2Funtitled1.jpg?alt=media&token=da803fb3-ba32-41a5-b1a9-b2dd6e4efe23",
		title: "Untitled",
		category: "watercolor",
		width: 8.5,
		height: 12,
		year: 2019,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FAfternoonOfAFaun.jpg?alt=media&token=7a83db62-dc1b-4c94-94a6-f15c095ff897",
		title: "l'Après-midi d'un Faune",
		category: "tapestry",
		width: 14.5,
		height: 17,
		year: 2020,
		medium: "Acrylic on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FMigration1.jpg?alt=media&token=d883f2d4-2e52-448b-8c71-e7eb499e085c",
		title: "Migration 1",
		category: "tapestry",
		width: 20,
		height: 17,
		year: 2020,
		medium: "Acrylic on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FMigration2.jpg?alt=media&token=36355847-09a2-417a-aca4-7631c897afd9",
		title: "Migration 2",
		category: "tapestry",
		width: 35,
		height: 30,
		year: 2020,
		medium: "Acrylic on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FMigration3.jpg?alt=media&token=6187c19f-bcff-41da-8500-3ac93a81f724",
		title: "Migration 3",
		category: "tapestry",
		width: 17,
		height: 12,
		year: 2020,
		medium: "Acrylic on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FAdolescence.jpg?alt=media&token=b9be7b40-85a1-47b2-a6f2-644c659cfee3",
		title: "Adolescence",
		category: "icons",
		width: 12,
		height: 18,
		year: 2020,
		medium: "Mixed media on panel",
		wide: true
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FBeingInTheWorld.jpg?alt=media&token=5d118c1f-5072-4ff6-975b-2c474329ecbb",
		title: "Being in the World",
		category: "collage",
		width: 5,
		height: 8,
		year: 2016,
		medium: "Paper collage",
		status: ArtStatus.NOT_FOR_SALE
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FCreation.jpg?alt=media&token=1af9e1c6-64d1-4f28-bd91-c22f702a7f7c",
		title: "Creation",
		category: "mm3d",
		width: 8,
		height: 10,
		year: 2020,
		medium: "Iron with walnut pedestal",
		status: ArtStatus.NOT_FOR_SALE
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FCreature.jpg?alt=media&token=914a31dc-5c97-4d0c-8eb8-fd01dea1e84d",
		title: "Creature",
		category: "oil",
		width: 12,
		height: 20,
		year: 2009,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2Fdiptych.jpg?alt=media&token=178a7c45-349b-4269-a1ca-ab44f2370c0e",
		title: "Beauty and Grief: A Secular Altarpiece",
		category: "icons",
		width: 20,
		height: 10,
		year: 2020,
		medium: "Oil on panel",
		wide: true
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FGeneration.jpg?alt=media&token=9e8dd30b-866d-4573-81b1-2eea21995c55",
		title: "Generation",
		category: "icons",
		width: 12,
		height: 18,
		year: 2020,
		medium: "Mixed media"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FIcon.jpg?alt=media&token=6198911e-18e7-4aa0-b182-fbc48b7a304b",
		title: "Icon",
		category: "icons",
		width: 14,
		height: 18,
		year: 2020,
		medium: "Mixed media"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FMementoMori.jpg?alt=media&token=8d9aaa5e-3c12-4b98-860d-6e52d2d9b16b",
		title: "Memento Mori",
		category: "oil",
		width: 10,
		height: 20,
		year: 2010,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FMementoMoriBlue.jpg?alt=media&token=04acc1ee-e966-48cc-a5cc-04cfe8ae8178",
		title: "Memento Mori Blue",
		category: "oil",
		width: 10,
		height: 20,
		year: 2010,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FMigration4.jpg?alt=media&token=9c62e033-6d9a-4312-82df-f12a289c9efa",
		title: "Migration 4",
		category: "tapestry",
		width: 13,
		height: 5,
		year: 2020,
		medium: "Acrylic on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FMigration5.jpg?alt=media&token=a8367813-7ff0-4f93-8bea-4ee1fefaa440",
		title: "Migration 5",
		category: "tapestry",
		width: 35,
		height: 30,
		year: 2020,
		medium: "Acrylic on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FSpeakingToTheDead.jpg?alt=media&token=6d69b091-e647-4559-9366-f647da15691d",
		title: "Speaking to the Dead",
		category: "collage",
		width: 5,
		height: 8,
		year: 2016,
		medium: "Paper collage",
		status: ArtStatus.NOT_FOR_SALE
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FTesseract.jpg?alt=media&token=9b29e9fb-a40b-45e8-aadd-23f46838fd17",
		title: "Tesseract",
		category: "oil",
		width: 12,
		height: 20,
		year: 2010,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FTheGoddessOfGlobalWarming.jpg?alt=media&token=e46bd646-ebe6-46fa-ac59-a9b4ca18345a",
		title: "The Goddess of Global Warming",
		category: "collage",
		width: 5,
		height: 3,
		year: 2019,
		medium: "Paper collage"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FTheStoryOfMyLife.jpg?alt=media&token=212deba9-c788-4760-b4dc-a444173fd2bc",
		title: "The Story of My Life",
		category: "mm3d",
		width: 12,
		height: 12,
		year: 2015,
		medium: "Mixed media"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FWeAreTheGuardians.jpg?alt=media&token=ad9bb1d9-0f1d-4800-b282-9622e49afd35",
		title: "We Are the Guardians",
		category: "collage",
		width: 5,
		height: 8,
		year: 2017,
		medium: "Paper collage"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FWhereDoWeGoFromHere.jpg?alt=media&token=bc9f121e-75ff-4282-a87f-e6f5a00e41bb",
		title: "Where Do We Go From Here?",
		category: "collage",
		width: 5,
		height: 8,
		year: 2021,
		medium: "Paper collage"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHome4.jpg?alt=media&token=db2b07ca-6c69-4c38-a0af-1acf36342df1",
		title: "At Home",
		category: "mm3d",
		width: 8,
		height: 10,
		year: 2016,
		medium: "Mixed media"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHomeBirds3.jpg?alt=media&token=40d7c168-6e09-437a-a0ea-1ed112259d73",
		title: "Home Birds 3",
		category: "mm3d",
		width: 10,
		height: 8,
		year: 2015,
		medium: "Mixed media"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FInFairyland.jpg?alt=media&token=a03ce229-71c7-4f49-9f82-7054fa52d98d",
		title: "In Fairyland",
		category: "mm3d",
		width: 7,
		height: 9,
		year: 2019,
		medium: "Mixed media hinged box"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FStar.jpg?alt=media&token=79bb529f-1cee-418a-a3a2-cb449926b9ef",
		title: "Star",
		category: "oil",
		width: 10,
		height: 12,
		year: 2008,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FTheSecretsGarden_outside.jpg?alt=media&token=a9affad2-7089-4749-87b9-b2604240718e",
		title: "The Secrets Garden (outside)",
		category: "mm3d",
		approx: true,
		width: 32,
		height: 10,
		year: 2021,
		medium: "Mixed media",
		wide: true
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FTheSecretsGarden_inside.jpg?alt=media&token=242c21f9-20c6-48cb-9093-1e8a54fc15cf",
		title: "The Secrets Garden (inside)",
		category: "mm3d",
		approx: true,
		width: 32,
		height: 10,
		year: 2021,
		medium: "Mixed media",
		wide: true
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FUnbound.jpg?alt=media&token=95e43fb6-231a-42b0-a444-e904ac68970f",
		title: "Unbound",
		category: "figure",
		width: 12,
		height: 16,
		year: 1998,
		medium: "Ink and watercolor on paper",
		status: ArtStatus.SOLD
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FTheDarkRoom.jpg?alt=media&token=e8f24550-7943-43b6-b1d3-f2ba973261c0",
		title: "The Dark Room",
		category: "oil",
		width: 8,
		height: 12,
		year: 2021,
		medium: "Oil on gessoed paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2Fjulia.jpg?alt=media&token=294402fb-df39-411e-80fb-b0fdaa88dc43",
		title: "Nothing Echoes in the Cage of Ribs",
		category: "oil",
		width: 18,
		height: 24,
		year: 2022,
		medium: "Oil and gauze on panel"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FSkull.jpg?alt=media&token=9b8c90f6-3cfc-434d-89ec-f2f777cad338",
		title: "Green Skull",
		category: "oil",
		width: 12,
		height: 12,
		year: 2022,
		medium: "Oil on masonite"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FSkulls.jpg?alt=media&token=e29b12e1-ac78-4338-8bec-8bf15b2b8096",
		title: "Blue Skulls",
		category: "oil",
		width: 16,
		height: 13,
		year: 2022,
		medium: "Oil on masonite"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FShell.jpg?alt=media&token=98a0a1f2-08ec-4c24-a170-5a5e65a4d9b7",
		title: "Shell",
		category: "oil",
		width: 11.5,
		height: 10,
		year: 2022,
		medium: "Oil on masonite"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings_Reckoning.jpg?alt=media&token=ca03c902-9dd9-43c3-a531-4c8bcc293f0d",
		title: "Reckoning",
		category: "hope-springs",
		width: 12,
		height: 16,
		year: 2023,
		medium: "Gessoed panel"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings_WhiteLies.jpg?alt=media&token=e908b29d-af21-41f5-b1c1-bb6daf5010a5",
		title: "White Lies",
		category: "hope-springs",
		width: 11,
		height: 15,
		year: 2022,
		medium: "Oil on gessoed paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FEvent.jpg?alt=media&token=6ddba046-3125-4fa5-be43-dca29ce2b0c8",
		title: "Past Present",
		category: "hope-springs",
		width: 24,
		height: 60,
		year: 2023,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FEvent2.jpg?alt=media&token=6166bbac-ff82-4586-837a-7d0fcd02bd7e",
		title: "Present Future",
		category: "hope-springs",
		width: 24,
		height: 60,
		year: 2023,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHalfTruths.jpg?alt=media&token=c6131c71-f30e-433f-a540-bcb3fd24a1b7",
		title: "Half Truths",
		category: "hope-springs",
		width: 11,
		height: 15,
		year: 2022,
		medium: "Oil on gessoed paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings4.jpg?alt=media&token=a3e70d90-3f9d-434c-b6b9-23a90f169b54",
		title: "Hope Springs IV",
		category: "hope-springs",
		width: 11,
		height: 14,
		year: 2022,
		medium: "Oil on gessoed paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings3.jpg?alt=media&token=ffe332d9-921b-4657-8da2-83da91e41c7f",
		title: "Hope Springs III",
		category: "hope-springs",
		width: 10,
		height: 14,
		year: 2022,
		medium: "Gouache on watercolor paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings2.jpg?alt=media&token=0b809412-23a1-4cc2-9ef0-0cd58169586c",
		title: "Hope Springs II",
		category: "hope-springs",
		width: 10,
		height: 14,
		year: 2021,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings1.jpg?alt=media&token=142c0486-69d0-46bd-bb51-777bb4e45fd3",
		title: "Hope Springs I",
		category: "hope-springs",
		width: 10,
		height: 14,
		year: 2021,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings_SilverBridge.jpg?alt=media&token=0fb99590-7839-412c-ba1d-54b6db30f840",
		title: "Silver Bridge",
		category: "hope-springs",
		width: 9,
		height: 12,
		year: 2021,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings_TheFlood.jpg?alt=media&token=682c4596-34a9-4f8b-8eb4-da01ae7d6700",
		title: "The Flood",
		category: "hope-springs",
		width: 9,
		height: 12,
		year: 2021,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings_HikeInTheClouds.jpg?alt=media&token=54fe558b-cb06-4de5-a805-4d2c98cc95b0",
		title: "Hike in the Clouds",
		category: "hope-springs",
		width: 10,
		height: 15,
		year: 2021,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings_NightHike.jpg?alt=media&token=a7298e6b-7408-407a-8974-0d0945bda6fd",
		title: "Night Hike",
		category: "hope-springs",
		width: 10,
		height: 13,
		year: 2021,
		medium: "Watercolor on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings_Sketch1.jpg?alt=media&token=7f2cbdd6-f599-4794-a5b9-2a23d37a64c0",
		title: "Sketch 1",
		category: "hope-springs",
		width: 11,
		height: 15,
		year: 2021,
		medium: "Oil pastels on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings_Sketch2.jpg?alt=media&token=cdfcd12d-8793-46a9-9646-5a0dee143544",
		title: "Sketch 2",
		category: "hope-springs",
		width: 10,
		height: 12,
		year: 2021,
		medium: "Oil pastels on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings_Sketch3.jpg?alt=media&token=759536ed-d467-4954-81ba-36848621de20",
		title: "Sketch 3",
		category: "hope-springs",
		width: 11,
		height: 7.5,
		year: 2021,
		medium: "Oil pastels on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings_Sketch4.jpg?alt=media&token=2aa73e50-b655-4c80-bf5e-455a3eb7d2d6",
		title: "Sketch 4",
		category: "hope-springs",
		width: 10,
		height: 12,
		year: 2021,
		medium: "Oil pastels on paper"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHopeSprings5.jpg?alt=media&token=35ac403e-a987-4841-a5da-9cb4c52b1ceb",
		title: "Hope Springs V",
		category: "hope-springs",
		width: 10,
		height: 15,
		year: 2023,
		medium: "Oil and gauze on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FShedExterior_Ohio.jpg?alt=media&token=65ca66b0-573a-4bcb-9cc7-ad3ab131bfd0",
		title: "Shed: Ohio",
		category: "oil",
		width: 14,
		height: 16,
		year: 2023,
		medium: "Oil on canvas covered panel"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FShed_Virginia.jpg?alt=media&token=dccb1ca6-640e-428f-a1d1-f0e90a3dc0d9",
		title: "Shed: Virginia",
		category: "oil",
		width: 14,
		height: 16,
		year: 2023,
		medium: "Oil on canvas covered panel"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FShedInterior_Ohio.jpg?alt=media&token=c82bf4ff-e163-4917-aa3f-ebb9194786a3",
		title: "Barn Interior: Ohio",
		category: "oil",
		width: 14,
		height: 16,
		year: 2023,
		medium: "Oil on canvas covered panel"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2Fgarage_ohio.jpg?alt=media&token=cf1ca884-2c69-4bbe-b40d-256af273b21a",
		title: "How the Light Gets In: Ohio",
		category: "oil",
		width: 24,
		height: 24,
		year: 2023,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FBuddhistMandala.jpg?alt=media&token=468a42eb-a685-4d90-a7bc-6e47f8e53046",
		title: "Peace Mandala 1",
		category: "mandala",
		width: 7,
		height: 7,
		year: 2019,
		medium: "Watercolor on leaf paper",
		status: ArtStatus.NOT_FOR_SALE
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FBuilding_Delaware.jpg?alt=media&token=bfecb0ef-eb77-490c-a37c-8bdffd30a4b7",
		title: "Building Block: Delaware",
		category: "oil",
		width: 24,
		height: 18,
		year: 2023,
		medium: "Oil on canvas board"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FWinterStreetGoddess.jpg?alt=media&token=2e33ec50-545c-419c-84ac-1c15c923351a",
		title: "Winter Street Goddess",
		category: "oil",
		width: 10,
		height: 24,
		year: 2023,
		medium: "Oil on canvas covered panel"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FDanceKnight.jpg?alt=media&token=a39c9c22-9c67-494f-a8d1-493a7d9c3f22",
		title: "Dance Knight",
		category: "oil",
		width: 10,
		height: 25,
		year: 2023,
		medium: "Oil on masonite"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FHomageGrisCornell.jpg?alt=media&token=2a4f1107-9dc2-4468-ae23-f8e1fd2ea793",
		title: "Homage Gris/Cornell",
		category: "mm3d",
		width: 12,
		height: 12,
		year: 2023,
		medium: "Mixed media"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FKarin52.jpg?alt=media&token=eecc2665-e057-4f47-9956-d78104bc05fc",
		title: "Karin '52",
		category: "mm3d",
		width: 7,
		height: 7,
		year: 2019,
		medium: "Mixed media with antique photo"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FMinnesotaWild.jpg?alt=media&token=9c2ac724-fd9d-4f42-ae86-39a2ee1e82b3",
		title: "Minnesota Wild",
		category: "collage",
		width: 5,
		height: 8,
		year: 2023,
		medium: "Paper collage"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FMyLittleChickens.jpg?alt=media&token=053fda8c-aa1e-428f-8e9d-02f1cd2d600a",
		title: "To My Little Chickens",
		category: "mm3d",
		width: 6,
		height: 6,
		year: 2022,
		medium: "Mixed media with photo"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FNunTooSoon.jpg?alt=media&token=d30ac81e-b1d3-4bc2-ab03-999bbb52e788",
		title: "Nun Too Soon",
		category: "mm3d",
		width: 10,
		height: 10,
		year: 2022,
		medium: "Mixed media with antique photo"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FPeaceMandala.jpg?alt=media&token=535e6de7-bcca-4e71-9f97-d223a66c54b0",
		title: "Peace Mandala 2",
		category: "mandala",
		width: 7,
		height: 7,
		year: 2020,
		medium: "Watercolor on leaf paper",
		status: ArtStatus.NOT_FOR_SALE
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FSunMoonMandala.jpg?alt=media&token=d32d6437-bcff-4deb-9198-ecfd83e3640e",
		title: "Sun/Moon Mandala",
		category: "mandala",
		width: 7,
		height: 7,
		year: 2022,
		medium: "Watercolor on leaf paper",
		status: ArtStatus.NOT_FOR_SALE
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FTurtleShellMandala.jpg?alt=media&token=37369ad0-3c51-4656-b610-30ac4fc563ab",
		title: "Turtle Shell Mandala",
		category: "mandala",
		width: 7,
		height: 7,
		year: 2021,
		medium: "Watercolor on leaf paper",
		status: ArtStatus.NOT_FOR_SALE
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FYinYangMandala.jpg?alt=media&token=31bf94d5-5f3e-4826-94ec-5ad970089154",
		title: "Yin/Yang Mandala",
		category: "mandala",
		width: 24,
		height: 24,
		year: 2023,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FTheWomenLeave.jpg?alt=media&token=b9dde7de-68b7-4515-ba4c-b50a9907700d",
		title: "The Women Leave",
		category: "hope-springs",
		width: 12,
		height: 22,
		year: 2023,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FAtWorldsEnd.jpg?alt=media&token=9db03245-6b41-4397-aafd-0bc6e569092e",
		title: "At World's End",
		category: "hope-springs",
		width: 18,
		height: 22,
		year: 2023,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FAnotherWorld.jpg?alt=media&token=ae85fc14-83ae-4a8a-b60c-0d7e30e032d6",
		title: "Another World",
		category: "oil",
		width: 40,
		height: 30,
		year: 2023,
		medium: "Oil on textured canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FTheMast.jpg?alt=media&token=e7db54ef-afa7-4d83-85d8-c4e5e2cf4f5e",
		title: "The Mast",
		category: "hope-springs",
		width: 13,
		height: 32,
		year: 2024,
		medium: "Oil on panel"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FBethlehemSteel.jpg?alt=media&token=35ec63db-4583-44ab-a1f9-11149e09c90b",
		title: "Bethlehem Steel",
		category: "oil",
		width: 16,
		height: 18,
		year: 2024,
		medium: "Oil on canvas covered panel"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FBethlehemPA.jpg?alt=media&token=cc50b4cc-eba7-4bd9-9ed9-4bd1dabf4eab",
		title: "Bethlehem, PA",
		category: "oil",
		width: 16,
		height: 18,
		year: 2024,
		medium: "Oil on canvas covered panel"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2Fapple.jpg?alt=media&token=0ce6ca8c-f0a7-41da-ab1b-142440c5656f",
		title: "Far From the Tree",
		category: "oil",
		width: 11,
		height: 15,
		year: 2024,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FTheRunDelaware.jpg?alt=media&token=6d98a926-9062-48b1-aef3-109ec71286e5",
		title: "The Run: Delaware",
		category: "oil",
		width: 18,
		height: 24,
		year: 2024,
		medium: "Oil on canvas"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FSeascape_Duluth.jpg?alt=media&token=d73ffd37-761b-40e5-b009-0f23cbe19f1a",
		title: "Seascape: Duluth",
		category: "oil",
		width: 16,
		height: 24,
		year: 2024,
		medium: "Oil on canvas covered panel"
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/k-kahn-art.appspot.com/o/art%2FCarriageHouse_Delaware.jpg?alt=media&token=417e5c9f-951d-453f-a536-d7df88ba03d2",
		title: "Carriage House: Ohio",
		category: "oil",
		width: 16,
		height: 18,
		year: 2024,
		medium: "Oil on canvas covered panel"
	}
];
